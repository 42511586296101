import './Mapa.css'

const Mapa = () => {
    return (
        <section className='mapa'>
            <h2>Local</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14645.310655599102!2d-46.54515249999998!3d-23.412527949999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce8ab153572acf%3A0x1d19ccca45ad6fc3!2sAv.%20Comendador%20Wilson%20Talarico%2C%20372%20-%20Vila%20Florida%2C%20Guarulhos%20-%20SP%2C%2007196-080!5e0!3m2!1spt-BR!2sbr!4v1674168998034!5m2!1spt-BR!2sbr" title='Mapa' />
            <h3>Av. Comendador Wilson Talarico, nº 372 Vila Flórida <br/>  Guarulhos SP CEP 07196-080</h3>
        </section>
    )
}

export default Mapa