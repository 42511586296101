const DeskFooter = () => {
    return (
        <footer className="footer">
                <div className="footer__contato">
                    <ul>
                        <li><h3>Contato</h3></li>
                        <li><a href="http://wa.me/+5511993438561"> <img src="Imagens/logo-whats-bc.svg" alt="logo whats" /> (11) 99343-8561 </a></li>
                        <li><a href="http://wa.me/+5511999202710"><img src="Imagens/logo-whats-bc.svg" alt="logo whats" /> (11) 99920-2710 </a></li>
                        <li><img src="Imagens/Icon material-email.svg" alt="Email" /> cczanollo588@gmail.com.br</li>
                        <li><img src="Imagens/Icon material-email.svg" alt="Email" /> danizanollo@gmail.com</li>
                    </ul>
                </div>
                <div className="imagem">
                    <img src="Imagens/logo.webp" alt="Logo CCZ" />
                </div>
                <div className="endereco">
                    <ul>
                        <li><h3>Endereço</h3></li>
                        <li><img src="Imagens/Icon awesome-map-marker-alt.svg" alt="Local" />Av. Comendador Wilson Talarico, 372 </li>
                        <li>Vila Flórida - Guarulhos / SP </li>
                        <li>CEP 07196-080</li>
                    </ul>
                </div>
        </footer >
    )
}

export default DeskFooter