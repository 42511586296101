import './Sobre.css'

const Sobre = () => {
    return (
        <section id="sobre">
            <img src="Imagens/tinta-impressora.webp" alt="Tintas timpressoras" />
            <div className="conteudo">
                <h2>Sobre nós</h2>
                <p><b>CCZ Copiadoras e Suprimentos</b> é uma empresa focada em manutenção de impressoras e copiadoras, atuando a 15 anos no mercado, trabalhando com vendas de insumos, locação e visitas técnicas. <b>Confira nossos serviços! </b> </p>
                <p>Com a missão de facilitar o atendimento de nossos clientes, realizamos orçamento via e-mail e WhatsApp.</p>
                <h3>Orçamento gratuito e sem compromisso</h3>
            </div>
        </section>
    )
}

export default Sobre