import './App.css';
import Cabecalho from './Componentes/Cabecalho/Index';
import Carrousel from './Componentes/Carrousel/Index';
import Desenvolvedores from './Componentes/Desenvolvedores/Index';
import Footer from './Componentes/Footer/Index';
import Formulario from './Componentes/Formulario/Index';
import Mapa from './Componentes/Mapa/Index';
import Servicos from './Componentes/Servicos/Index';
import Sobre from './Componentes/Sobre/Index';


function App() {
  return (
    <div className="App">
      <Cabecalho />
      <main>
        <Sobre />
        <Servicos />
        <Carrousel />
        <Formulario />
        <Mapa />
      </main>
      <Footer />
      <Desenvolvedores />
    </div>
  );
}

export default App;
