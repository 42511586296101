import NavLinks from './NavLinks'
import { IoIosMenu } from 'react-icons/io'
import { useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc'

const MobileNavigation = () => {
    const [open, setOpen] = useState(false);

    const hamburgerIcon = <IoIosMenu className='hamburger'
        size='30px'
        onClick={() => setOpen(!open)}
    />

    const closeIcon = <VscChromeClose className='hamburger'
        size='30px'
        onClick={() => setOpen(!open)}
    />

    const closeMobileMenu = () => setOpen(false)


    return (
        <nav>
            {open ? closeIcon : hamburgerIcon}
            {open && <NavLinks isMobile={true}  closeMobileMenu={closeMobileMenu} />}
        </nav>

    );
}

export default MobileNavigation;
