import "./Banner.css";

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner__conteudo">
        <h1>
          <img src="Imagens/logo.webp" alt="Logo CCZ" className="logo" />
        </h1>
        <h2>Assistencia Tecnica de Impressoras e Copiadoras</h2>
        <p>
          Assistencia técnica especializada em conserto de copiadoras e
          impressoras de diversas marcas e modelos.
        </p>
        <a href="http://wa.me/+5511993438561"><img src="Imagens/logo-whats-bc.svg" alt="whats logo"></img> Contate-nos</a>
      </div>
      <div className="banner__printer">
        <img src="Imagens/printerBanner.webp" alt="Impressora" />
      </div>
    </div>
  );
};

export default Banner;
