import Banner from '../Banner/Index'
import Contato from '../Contato/Index'
import Navbar from '../Navbar/Index'
import './Cabecalho.css'

const Cabecalho = () => {
    return (
        <header className="cabecalho">
            <Contato />
            <div className="cabecalho__fundo">
                <Navbar />
                <Banner />
            </div>
        </header>
    )
}

export default Cabecalho