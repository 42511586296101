import './Contato.css'

const Contato = () => {
    return (
        <div className="contato">
            <p>
                <a href="tel:11993438561"><b>Contato: </b> 11 99343-8561 / 11 99920-2710</a>
                <img src="Imagens/logo-whats-azul.svg" alt="Whats Logo" />
            </p>
        </div>
    )
}

export default Contato