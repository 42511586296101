import { Link } from "react-scroll";

const NavLinks = (props) => {
    return (
        <ul className="navbar__lista">
            <li className="navbar__item" >
                <Link
                    activeClass="active"
                    to="sobre"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={() => props.isMobile && props.closeMobileMenu()}
                >
                    Sobre
                </Link>
            </li>
            <li className="navbar__item" >
                <Link
                    activeClass="active"
                    to="servicos"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={() => props.isMobile && props.closeMobileMenu()}
                >
                    Serviços
                </Link>
            </li>
            <li className="navbar__item" >
                <Link
                    activeClass="active"
                    to="formulario"
                    spy={true}
                    smooth={true}
                    offset={-10}
                    duration={500}
                    onClick={() => props.isMobile && props.closeMobileMenu()}
                >
                    Contato
                </Link>
            </li>
            <li className="navbar__item" >
                <Link
                    activeClass="active"
                    to="mapa"
                    spy={true}
                    smooth={true}
                    offset={-10}
                    duration={500}
                    onClick={() => props.isMobile && props.closeMobileMenu()}
                >
                    Localização
                </Link>
            </li>
        </ul>);
}

export default NavLinks