import DeskFooter from './DeskFooter'
import MobileFooter from './MobileFooter'
import './Footer.css'

const Footer = () => {
    return (
        <div>
            <div className='desk'>
                <DeskFooter />
            </div>
            <div className='mobile'>
                <MobileFooter />
            </div>
        </div >
    )
}

export default Footer