import './Servicos.css'
import { useEffect, useState } from "react";
import CardServicos from '../CardServicos/Index'

const Servicos = () => {
    const [servico, setServico] = useState([]);

    useEffect(() => {
        fetch("https://cczcopiadoras.com.br/servicos.json")
          .then((response) => response.json())
          .then(setServico);
      }, []);

      if (!servico || !servico.length) return null;

    return (
        <section id="servicos">
            <h2>Nossos Serviços</h2>
            <div className="cards">
                {servico.map((servicos) => {
                    const {title, image, text} = servicos;
                    return (
                        <CardServicos key={title} title={title} image={image} text={text}/>
                    )
                })}
            </div>

        </section>
    )
}

export default Servicos