import './Botao.css'

const Botao = () => {
    return (
        <div>
            <button className="botaoenviar" type="submit">Enviar</button>
        </div>
    )
}

export default Botao