import './CardServicos.css'

const CardServicos = ({ title, image, text }) => {
    return (
        <div className="cardservicos">
            <div className="card">
                <div className="card--titulo">
                    <h3>{title}</h3>
                </div>

                <div className="card--conteudo">
                    <div className="card--imagem">
                        <img src={image} alt={title} />
                    </div>
                    <div className="card--texo">
                        <p>{text}</p>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default CardServicos