import { useEffect, useState, useRef } from "react";
import "./Carrousel.css";

const Carrousel = () => {
  const [data, setData] = useState([]);
  const carrousel = useRef(null);

  useEffect(() => {
    fetch("https://cczcopiadoras.com.br/impressoras.json")
      .then((response) => response.json())
      .then(setData);
  }, []);

  if (!data || !data.length) return null;

  const handleLeftClick = (e) => {
    e.preventDefault();
    carrousel.current.scrollLeft -= carrousel.current.offsetWidth;
  };

  const handleRightClick = (e) => {
    e.preventDefault();
    carrousel.current.scrollLeft += carrousel.current.offsetWidth;
  };

  return (
    <section className="carrousel">
      <div className="titulo">
        <h2>Trabalhamos com as seguintes marcas</h2>
      </div>
      <div className="buttons">
        <button onClick={handleLeftClick}>
          <img src="Imagens/right_chevron_icon.webp" alt="Scroll Left" />
        </button>
        <div className="container">
          <div className="container__carrousel" ref={carrousel}>
            {data.map((item) => {
              const { name, image } = item;
              return (
                <div className="carrousel__item" key={name}>
                  <div className="item__image">
                    <img src={image} alt={name} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <button onClick={handleRightClick}>
          <img src="Imagens/right_chevron_icon.webp" alt="Scroll Right" />
        </button>
      </div>
    </section>
  );
};

export default Carrousel;
