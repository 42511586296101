import Botao from '../Botao/Index'
import CampoMensagem from '../CampoMensagem/Index'
import CampoTexto from '../CampoTexto/Index'
import { useState } from 'react'
import emailJs from '@emailjs/browser'
import './Formulario.css'

const Formulario = () => {

    const [nome, setNome] = useState('')
    const [sobreNome, setSobreNome] = useState('')
    const [email, setEmail] = useState('')
    const [mensagem, setMensagem] = useState('')

    const sendEmail = (evento) => {
        evento.preventDefault()

        const templateParams = {
            from_name: nome,
            from_lastName: sobreNome,
            email: email,
            message: mensagem
        }

        emailJs.send("service_bp66j0w", "template_briv339", templateParams, 'VY1QCgbPwLxVKH2ZK')
            .then((response) => {
                console.log(" Email Enviado com sucesso", response.status, response.text)
                setNome('')
                setSobreNome('')
                setEmail('')
                setMensagem('')
                
                alert('Email Enviado! Entraremos em contato assim que possivel')
            }, (err) => {
                console.log('Erro: ', err)
            })
    }

    return (
        <section>
            <form id='formulario' onSubmit={sendEmail}>
                <h2>Entre em contato</h2>

                <div className="formulario__conteudo formulario__conteudo-nome">
                    <CampoTexto
                        className="formulario__campo-nome"
                        tipo={'text'}
                        texto={'Nome'}
                        aoAlterado={valor => setNome(valor)}
                        obrigatorio={true}
                        valor={nome} />
                </div>
                <div className="formulario__conteudo formulario__conteudo-sobrenome">
                    <CampoTexto
                        className="formulario__campo-sobrenome"
                        tipo={'text'}
                        texto={'Sobrenome'}
                        aoAlterado={valor => setSobreNome(valor)}
                        valor={sobreNome}
                    />
                </div>
                <div className="formulario__conteudo formulario__conteudo-outros">
                    <CampoTexto
                        className="formulario__campo-email"
                        tipo={'email'}
                        texto={'Email'}
                        aoAlterado={valor => setEmail(valor)}
                        obrigatorio={true}
                        valor={email}
                    />

                    <CampoMensagem
                        aoAlterado={valor => setMensagem(valor)}
                        obrigatorio={true}
                        texto={'Mensagem'}
                        valor={mensagem}
                    />
                    <Botao />
                </div>
            </form>
        </section>
    )
}

export default Formulario