import './Navbar.css'
import Navigation from './Navigation'
import MobileNavigation from './MobileNavigation'


const Navbar = () => {
    return (
        <div className='navbar'>
            <div className='nav-desk'>
                <Navigation/>
            </div>
            <div className='nav-mobile'>
                <MobileNavigation/>
            </div>
        </div>
    )
}

export default Navbar