import NavLinks from "./NavLinks";

const Navigation = () => {
    return (
        <nav >
            <NavLinks />
        </nav>

    );
}

export default Navigation