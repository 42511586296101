import './Desenvolvedores.css'

const Desenvolvedores = () => {
    return (
        <div className="desenvolvido">
            <p>Designer:
                <a href="https://www.linkedin.com/in/mirian-ogata-646864234/"> Mirian Harumi</a>
            </p>
            <p>Desenvolvedor:
                <a href="https://www.linkedin.com/in/wesleymo22/"> Wesley Moraes</a>
            </p>
        </div>
    )
}

export default Desenvolvedores